var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.loading,
          expression: "loading"
        }
      ],
      ref: "three",
      staticClass: "three-canvas"
    },
    [
      _vm._t("label", [
        _vm.meshName
          ? _c("Label", {
              attrs: {
                availableMeshs: _vm.availableMeshs,
                meshName: _vm.meshName,
                idx: _vm.selectIdx
              }
            })
          : _vm._e()
      ])
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }